var MainMenu = (function(){

	var init = function(){

		document.querySelector('a.nav-toggle').addEventListener('click', toggle, true);
	},

	toggle = function(){
		var body = document.querySelector('body'), button = document.querySelector('header nav');
		body.classList.contains('menu-active') ? body.classList.remove('menu-active') : body.classList.add('menu-active');
		button.classList.contains('active') ? button.classList.remove('active') : button.classList.add('active'); 
	};

	return {
		init: init
	}
})();
MainMenu.init();