if (document.querySelector('.instafeed')) {
    var feed = new Instafeed({
        get: 'user',
        userId: '2291979572',
        sortBy: 'most-recent',
        clientId: '0af729a38af74d32bcd14193127b4aa2',
        accessToken: '2291979572.1677ed0.b399ed3563cc42ae98920bbc5d63cbcb'
    });
    feed.run();
}

var didScroll = false;
window.onscroll = function(){
	setScrollState();
}

function setScrollState() {
  didScroll = true;
}

setInterval(function() {
  if(didScroll) {
    didScroll = false;
  toggleHeader();
  }
}, 200);

$(document).ready(function(){
  $(".page-intro").fitVids();
  $(".image-collection").fitVids();
  isVisible.init();
  var toggle = new Toggle();
  toggle.init();

});

var swiper = new Swiper({
  el: '.swiper-container',
  centeredSlides: true,
  slideToClickedSlide: true,
  grabCursor: true,
  zoom: true,
  autoplay: {
    delay: 5000,
  },
  speed: 1000,
  scrollbar: {
    el: '.swiper-scrollbar',
  },
  keyboard: {
    enabled: true,
  },
  pagination: {
    el: '.swiper-pagination',
  },
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  }
});