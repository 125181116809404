// Example markup
// <div class="toggle">
// 	<a href="javascript:;" data-open="Read more" data-close="Hide"><span></span></a>
// 	<div class="tech-description">
// 		Your toggled content
// 	</div>
// </div>
var Toggle = function(){

	var init = function(){
		[].slice.call(document.querySelectorAll('.toggle')).forEach(function(item){
			item.addEventListener('click', toggle, false);
		})
	},

	toggle = function(event){
		if (event.currentTarget.classList.contains('open')){
			closeAll();
		} else {
			closeAll();
			event.currentTarget.classList.add('open')
		}

		function closeAll(){
			[].slice.call(document.querySelectorAll('.toggle')).forEach(function(item){
				item.classList.remove('open')
			})
		}
	}

	return {
		init: init
	}
};