var isVisible = (function() {
  var timeout = null;

  function isElementInViewport (el) {
    var rect = el.getBoundingClientRect();
    return (
      rect.top <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.left >= 0 &&
      rect.bottom > 0 && // (window.innerHeight || document.documentElement.clientHeight) && /*or $(window).height() */
      rect.right <= (window.innerWidth || document.documentElement.clientWidth) /*or $(window).width() */
    );
  }

  function visabilityChanged(el, callback) {
    clearTimeout(timeout);
    var old_visible;
    var visible = isElementInViewport(el);
    if (visible != old_visible) {
      old_visible = visible;
      if (visible) {
        el.classList.add('js-visible');
      } else {
        el.classList.remove('js-visible');
      }
      if (callback) {
        callback(visible);
      }
    }
  }

  function setTimer(el, callback) {
    timeout = setTimeout(
      function(){ 
        visabilityChanged(el, callback) 
      }, 250
    );
  };

  var watch = function(el, callback) {
    if (window.addEventListener) {
      addEventListener('DOMContentLoaded', function(){
        visabilityChanged(el, callback)
      }, false);
      addEventListener('load', function(){
        visabilityChanged(el, callback)
      }, false);
      addEventListener('scroll', function(){
        setTimer(el, callback)
      }, false);
      addEventListener('resize', function(){
        visabilityChanged(el, callback)
      }, false);
    } else if (window.attachEvent)  {
      attachEvent('onDOMContentLoaded', function(){
        visabilityChanged(el, callback)
      }, false);
      attachEvent('onload', function(){
        visabilityChanged(el, callback)
      }, false);
      attachEvent('onscroll', function(){
        visabilityChanged(el, callback)
      }, false);
      attachEvent('onresize',  function(){
        visabilityChanged(el, callback)
      }, false);
    }
  };

  var init = function() {
    var trackElements = document.querySelectorAll('.js-track-visibility');
    for (var i=0; i<trackElements.length; i++) {
      isVisible.watch(trackElements[i]);
    }
  }

  return {
    watch: watch,
    init: init
  }
})()