var toggleHeader = function(){
	if (!document.querySelector('body').classList.contains('menu-active')){
		if (document.querySelector('.site-container').getBoundingClientRect().top === 0){
			document.querySelector('.site-container').classList.remove('header-fixed');
			document.querySelector('header').classList.remove('fixed');
		} else {
			document.querySelector('.site-container').classList.add('header-fixed');
			document.querySelector('header').classList.add('fixed');
			document.querySelector('.site-container').style.paddingTop = document.querySelector('header').offsetHeight;
		}
	}
};